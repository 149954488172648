import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/retail.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Retail Employee Scheduling & Time Tracking Software | Camelo"
        metaDescription="Automate the management of your retail system using Camelo. Ease the concern on shift scheduling and improve communication among your team."
        header="Retail teams can now save more time and money."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
